import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, zip } from 'rxjs';
import { concatMap, filter, map, share } from 'rxjs/operators';

import { ISubscriptionLicense } from '@core/models/licenses-page-data.model';
import { ISubscription } from '@core/models/subscription.model';
import { IPagination } from '@core/models/pagination-params';
import { IMyPoliciesGraph } from '@core/models/my-policies.model';
import { IMySubscriptionsGraph } from '@core/models/my-subscriptions.model';
import { IGridSubscription } from '@core/models/grid-subscription.model';
import { buildPaginationParams } from '@shared/helpers/helpers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(private http: HttpClient) {}

  aproveSubscription = (id: string, publisher: string): Observable<any> => {
    const url = environment.APPROVESUBSCRIPTION.replace('{policyId}', id).replace('{subscriber}', publisher);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  getSubscriptionContract = (id: string, subscriberId: string): Observable<ISubscriptionLicense> => {
    const url = environment.LICENSES.replace('{subscriptionId}', id).replace('{subscriber}', subscriberId);
    return this.http.get<any>(url).pipe(share());
  };

  getPendingSubscriptions = (params?: IPagination, options = {}): Observable<ISubscription[]> =>
    this.http.get<ISubscription[]>(`${environment.GETPENDINGSUBSCRIPTIONS}${buildPaginationParams(params)}`, options);

  postRevokeSubscription = (policyId: string, subscriberId: string, reason: string, policyName: string): Observable<any> => {
    const data = { policyId, subscriberId, reason, policyName };
    return this.http.post(environment.REVOKESUBSCRIPTION, data);
  };

  getContractPreview = (policyId: string, subscriberId: string): Observable<any> => {
    const url = environment.PREVIEWDATASUSCRIBER.replace('{policyId}', policyId).replace('{subscriber}', subscriberId);
    return this.http.get<any>(url);
  };

  getDownloadReportUsage = (catalogId: string): Observable<any> => {
    const url = environment.USAGEPOLICY.replace('{policyId}', catalogId);
    return this.http.get(url, { responseType: 'arraybuffer' });
  };

  getPreviewData = (params): Observable<any> => {
    return this.getSubscriptionContract(params.policyId, params.subscriberId).pipe(
      filter(license => license.contractId !== null),
      concatMap((license: ISubscriptionLicense) => {
        const { policyId, subscriber } = license;
        return zip(of(license), this.getContractPreview(policyId, subscriber)).pipe(
          map(response => {
            const [license, tableData] = response;
            return { license, tableData };
          })
        );
      })
    );
  };

  getMySubscriptions = (): Observable<IGridSubscription[]> => {
    return this.http.get<IGridSubscription[]>(environment.MYSUBSCRIPTIONS).pipe(share());
  };

  getMySubscriptionsGraph = (): Observable<IMySubscriptionsGraph[]> => {
    return this.http.get<IMySubscriptionsGraph[]>(environment.MYSUBSCRIPTIONSGRAPH).pipe(share());
  };

  getMyPolicies = (): Observable<IGridSubscription[]> => {
    return this.http.get<IGridSubscription[]>(environment.MYPOLICIES).pipe(share());
  };

  getMyPoliciesGraph = (): Observable<IMyPoliciesGraph[]> => {
    return this.http.get<IMyPoliciesGraph[]>(environment.MYPOLICIESGRAPH).pipe(share());
  };
}
